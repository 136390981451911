import {
    EmployeeWithStatus,
    Employer,
    ReportAvailable,
    useGetEmployersQuery,
    useGetReportsAvailableQuery
} from "../graphql/generated/graphql";
import LoadingErrorDisplay from "../common/LoadingErrorDisplay";
import Accordion from '@mui/material/Accordion';
import React, {useContext, useEffect, useMemo, useState} from "react";
import {
    AccordionDetails,
    AccordionSummary,
    Breadcrumbs,
    Button,
    Card,
    Container,
    Divider,
    Grid,
    Typography
} from "@mui/material";
import ReportGeneratorModal from "../components/Report/ReportGeneratorModal";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {makeStyles} from "@mui/styles";
import {Link, useNavigate} from "react-router-dom";
import {APP_LINK} from "../common/AppLinks";
import ReportParametersForm from "../components/Report/ReportParamsForm/ReportParametersForm";
import RoeReport from "./RoeReport";
import {assumedUserContext} from "../components/User/AssumedUserContext";
import useAnonCookie from "../security/useAnonCookie";

const useStyles = makeStyles(() => ({
    card: {
        margin: '16px',
        padding: 24,
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
    },
}));

function BasicBreadcrumbs() {
    return (
        <Container maxWidth="lg" sx={{mb: 10}}>
            <div role="presentation">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link to={APP_LINK}>
                        Home
                    </Link>
                    <Typography variant="h5" color="text.primary">Reports</Typography>
                </Breadcrumbs>
            </div>
        </Container>
    );
}

function getSortedReports(reportsAvailable: Array<ReportAvailable> | undefined) {
    if (!reportsAvailable) {
        return undefined;
    }
    const copy = [...reportsAvailable];
    copy.sort((a, b) => a.displayOrder - b.displayOrder);
    return copy;
}

function getUniqueEmployerNames(employers: Array<Employer> | undefined) {
    if (!employers) {
        return undefined;
    }
    return [...new Set(employers.map(employer => employer.name))];
}

const Reports = () => {
    const classes = useStyles();
    const [openReport, setOpenReport] = useState<ReportAvailable | undefined>(undefined);
    const [employerName, setEmployerName] = useState<string>();
    const [reloadEmployees, setReloadEmployees] = useState(false);
    const navigate = useNavigate();
    const {assumedUser} = useContext(assumedUserContext);
    const {getAnonUserId} = useAnonCookie();

    const {
        data: reportsData,
        loading: reportsLoading,
        error: reportsError
    } = useGetReportsAvailableQuery();

    const {
        data: employersData,
        loading: employersLoading,
        error: employersError
    } = useGetEmployersQuery({
        variables: {
            userId: assumedUser.id,
            anonUserId: getAnonUserId()
        }
    });

    useEffect(() => {
        function reloadReportParamsPageWhenReportParamsUpdated() {
            setOpenReport((prevState) => {
                const reportId = prevState?.id;
                return reportsData?.getReportsAvailable.find(report => report.id === reportId);
            });
        }

        reloadReportParamsPageWhenReportParamsUpdated();
    }, [reportsData, setOpenReport]);

    function getReportParamsForm(selectedReport: ReportAvailable, employerName: string) {
        return (selectedEmployees: Array<EmployeeWithStatus>) => {
            if (selectedReport.id === 'ROE') {
                return <RoeReport closeReport={() => {
                    setOpenReport(undefined);
                }}
                />
            }
            return <ReportParametersForm
                close={() => {
                    setOpenReport(undefined);
                }}
                params={selectedReport.params}
                reportId={selectedReport.id}
                selectedEmployees={selectedEmployees}
                employerName={employerName}
            />
        };
    }

    useEffect(() => {
        if (!!openReport?.externalLink) {
            window.location.href = openReport.externalLink;
        } else if (!!openReport?.pagePath) {
            navigate(`/reports/${openReport.pagePath}`);
        }
    }, [openReport, navigate]);

    const sortedReports = useMemo(() => getSortedReports(reportsData?.getReportsAvailable), [reportsData]);
    const employerNames = getUniqueEmployerNames(employersData?.getEmployers);

    useEffect(() => {
        if (!employerNames || employerNames.length < 1) {
            return;
        }
        if (!employerName || employerNames?.find(name => name === employerName) === undefined
        ) {
            setEmployerName(employerNames[0]);
        }
    }, [employerName, employerNames])

    if (!!openReport && !openReport.externalLink && !openReport.pagePath && employerNames && employerName) {
        return <ReportGeneratorModal
            getReportParamsForm={getReportParamsForm(openReport, employerName)}
            openDialog={!!openReport}
            reportName={openReport.name}
            close={() => setOpenReport(undefined)}
            reloadEmployees={reloadEmployees}
            setReloadEmployees={setReloadEmployees}
            employerNames={employerNames}
            setEmployerName={(employerName) => {
                setEmployerName(employerName);
                setReloadEmployees(true);
            }}
            selectedEmployerName={employerName}
        />
    }

    if (reportsLoading || reportsError || employersLoading || employersError) {
        return <LoadingErrorDisplay
            loading={reportsLoading || employersLoading}
            apolloError={reportsError || employersError}
        />
    }


    return <>
        <BasicBreadcrumbs/>
        <Container maxWidth="lg">
            <Card className={classes.card}>
                <Grid container alignItems="center" justifyContent="space-between">
                    {!!sortedReports && sortedReports.map((report) => (
                        <Grid item xs={12} key={report.id}>
                            <Accordion sx={{border: '1px solid #add8e6'}}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon style={{fontSize: "3rem"}}/>}
                                                  sx={{fontSize: '2.4rem'}}>
                                    {report.name}
                                </AccordionSummary>
                                <Divider/>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            {report.description}
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Button
                                                sx={{p: 1, mt: 2}}
                                                fullWidth
                                                variant={"contained"}
                                                onClick={() => setOpenReport(report)}>
                                                Generate Report
                                            </Button>
                                        </Grid>
                                        <Grid xs={12} md={4} display="flex" justifyContent="flex-end" sx={{pt: 2}}>
                                            {!!report.videoGuideLink &&
                                                <Link
                                                    target={"_blank"}
                                                    to={report.videoGuideLink}
                                                >
                                                    <Button
                                                        sx={{p: 1, mt: 2}}
                                                        fullWidth
                                                        variant={"contained"}
                                                    >
                                                        Video guide
                                                    </Button>

                                                </Link>
                                            }
                                        </Grid>
                                        <Grid xs={12} md={4} display="flex" justifyContent="flex-end" sx={{pt: 2}}>
                                            {!!report.infoLink &&
                                                <Link
                                                    target={"_blank"}
                                                    to={report.infoLink}
                                                >
                                                    <Button
                                                        sx={{p: 1, mt: 2}}
                                                        fullWidth
                                                    >
                                                        More info
                                                    </Button>

                                                </Link>
                                            }
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    ))}
                </Grid>
            </Card>
        </Container>
    </>
}

export default Reports;