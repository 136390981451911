import ErrorDisplay from "./ErrorDisplay";
import SuccessMessageDisplay from "./SuccessMessageDisplay";
import React from "react";
import {Result} from "../graphql/generated/graphql";

type PropsType = {
    result: Result | null;
    defaultErrorMessage: string;
    defaultSuccessMessage: string;
}

const ResultDisplay = (props: PropsType) => {
    const {result, defaultErrorMessage, defaultSuccessMessage} = props;
    if (!result) {
        return <></>;
    }
    const {successful, message} = result;

    if (!successful) {
        return <ErrorDisplay
            stringError={message || defaultErrorMessage}
        />
    }
    if (successful) {
        return <SuccessMessageDisplay
            messages={message || defaultSuccessMessage}/>
    }
}
export default ResultDisplay;