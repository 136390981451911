import useGetProvinces from "../Utils/useGetProvinces";
import LoadingErrorDisplay from "./LoadingErrorDisplay";
import React, {ReactNode} from "react";
import {ClaimCode, DentalCode, PayFrequency, Province} from "../graphql/generated/graphql";
import useGetPayFrequencies from "../Utils/useGetPayFrequencies";
import useGetClaimCodes from "../Utils/useGetClaimCodes";
import useGetDentalCodes from "../Utils/useDentalCodes";


type EnumsProvided = {
    provinces: Province[],
    payFrequencies: PayFrequency[],
    claimCodes: ClaimCode[]
    dentalCodes: DentalCode[]
}
type PropsType = {
    children: (data: EnumsProvided) => ReactNode;
}

const EnumSupplier = (props: PropsType) => {
    const {children} = props;

    const {
        provinces,
        error: provincesError,
        loading: provincesLoading
    } = useGetProvinces();

    const {
        payFrequencies,
        error: payFrequenciesError,
        loading: payFrequenciesLoading
    } = useGetPayFrequencies();

    const {
        claimCodes,
        error: claimCodesError,
        loading: claimCodesLoading
    } = useGetClaimCodes();

    const {
        dentalCodes,
        error: dentalCodesError,
        loading: dentalCodesLoading
    } = useGetDentalCodes();

    if (!provinces || !payFrequencies || !claimCodes || !dentalCodes) {
        return <LoadingErrorDisplay
            loading={provincesLoading || payFrequenciesLoading || claimCodesLoading || dentalCodesLoading}
            apolloError={provincesError || payFrequenciesError || claimCodesError || dentalCodesError}
        />
    }


    return <>{children({provinces, payFrequencies, claimCodes, dentalCodes})}</>;
}
export default EnumSupplier;