import {DentalCode, useDentalCodesQuery} from "../graphql/generated/graphql";
import {ApolloError} from "@apollo/client";

type ReturnType = {
    dentalCodes: Array<DentalCode> | undefined;
    loading: boolean;
    error: ApolloError | undefined;
}
const useGetDentalCodes = (): ReturnType => {

    const {
        data,
        loading,
        error
    } = useDentalCodesQuery();

    return {
        dentalCodes: data?.dentalCodes,
        loading,
        error
    }
}

export default useGetDentalCodes;