import React, {ReactNode, useContext, useEffect} from "react";
import Intercom from '@intercom/messenger-js-sdk';
import {userContext} from "../components/User/UserContext";
import {getDateFromString} from "../Utils/dateFormatter";
import useSystemNotices from "../Utils/useSystemNotices";

type PropsType = {
    children: ReactNode;
}

const IntercomWidget = (props: PropsType) => {
    const {children} = props;
    const {user} = useContext(userContext);
    const [intercomLoaded, setIntercomLoaded] = React.useState(false);
    const {sendNotice} = useSystemNotices();

    Intercom({
        app_id: 'xso5kkqb',
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        created_at: user.createdDate ? getDateFromString(user.createdDate).unix() : undefined,
        user_hash: user.hash ? user.hash : undefined,
    });

    const className = "intercom-lightweight-app-launcher";
    const observer = new MutationObserver(() => {
        const element = document.querySelector(`.${className}`);
        if (!!element) {
            setIntercomLoaded(true);
        }
    });
    observer.observe(document.body, {childList: true, subtree: true});

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (!intercomLoaded) {
                sendNotice(`Intercom did not load for user: ${user.email}`);
            }
        }, 10000);
        return () => clearTimeout(timeoutId);
    }, [intercomLoaded, sendNotice, user.email]);


    return <>
        {children}
    </>
}
export default IntercomWidget;