import {InputLabel, MenuItem, Select} from "@mui/material";
import React from "react";

type PropsType = {
    setEmployerName?: (employerName: string) => void;
    employerNames?: string[];
    selectedEmployerName?: string;
}

const ReportEmployerSelect = (props: PropsType) => {
    const {setEmployerName, employerNames, selectedEmployerName} = props;

    const employerLabel = "Employer"
    return <>
        <InputLabel
            sx={{pl: 1}}
            id={"employer"}
        >
            {employerLabel}
        </InputLabel>
        <Select
            sx={{marginBottom: 1}}
            id={"employer"}
            label={employerLabel}
            onChange={event => {
                const newEmployerName = event.target.value;
                if (setEmployerName) {
                    setEmployerName(newEmployerName)
                }
            }}
            value={selectedEmployerName}
        >
            {
                employerNames && employerNames.map(employer => {
                        return (<MenuItem
                                key={employer}
                                value={employer}
                            >
                                {employer}
                            </MenuItem>
                        )
                    }
                )
            }
        </Select>
    </>
}
export default ReportEmployerSelect;