import {IncomeType, TaxTreatment} from "../../graphql/generated/graphql";
import {Button, Dialog, DialogContent, DialogTitle, Divider, Grid2, TextField, Typography} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React, {useState} from "react";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import CloseDialogButton from "../../common/CloseDialogButton";
import {useNavigate} from "react-router-dom";
import IncomeTypeEdit from "./IncomeTypeEdit";

type PropsType = {
    incomeTypes: Array<IncomeType>
    close: () => void;
    incomeType: IncomeType;
    currentPayStubId: number | undefined;
    setIncomeType: (incomeType: IncomeType) => void;
}

export function getEmptyIncomeType(taxTreatment: TaxTreatment): IncomeType {
    return {
        id: 0,
        label: "",
        rateLabel: "$/hr",
        unitsLabel: "Hrs",
        taxTreatment: taxTreatment,
        displayOrder: 0,
        isBenefit: false
    };
}

type IncomeTypeAutoComplete = {
    label: string;
    incomeType: IncomeType
}
const getIncomeTypeAutoComplete = (incomeType: IncomeType): IncomeTypeAutoComplete => {
    return {
        label: incomeType.label,
        incomeType: incomeType
    }
}

export const SALARY_TAX_TREATMENT: TaxTreatment = {
    code: "SALARY",
    label: "Regular pay",
    description: ""
}

const getIncomeTypeOptions = (incomeTypes: Array<IncomeType>): ReadonlyArray<IncomeTypeAutoComplete> => {
    let incomeTypeOptions = incomeTypes.map(incomeType => {
            return getIncomeTypeAutoComplete(incomeType);
        }
    );
    const newIncomeTypeOption: IncomeTypeAutoComplete = {
        label: "Add a new type + ",
        incomeType: getEmptyIncomeType(SALARY_TAX_TREATMENT)
    };
    incomeTypeOptions.push(newIncomeTypeOption);
    return incomeTypeOptions;
}


const IncomeTypeSelectEdit = (props: PropsType) => {
    const {
        incomeTypes,
        close,
        incomeType,
        setIncomeType,
        currentPayStubId,
    } = props;


    const [editMode, setEditMode] = useState<boolean>(false);

    const navigate = useNavigate();

    const selectIncomeType = (selectedAutoCompleteOption: IncomeTypeAutoComplete) => {
        const selectedType = selectedAutoCompleteOption.incomeType;
        if (!selectedType.id || selectedType.id === 0) {
            // newIncomeType();
            setEditMode(true);
        } else {
            setIncomeType(selectedType);
        }
    }

    let autoCompleteOptions: ReadonlyArray<IncomeTypeAutoComplete> = getIncomeTypeOptions(incomeTypes);

    if (!incomeType) {
        return <LoadingErrorDisplay
            loading={true}
        />
    }

    return <>
        <Grid2 container direction={"row"} alignItems="center" sx={{mt: 2}}>
            <Grid2 size={9}>
            <Autocomplete
                disabled={editMode}
                options={autoCompleteOptions}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select income type"
                        fullWidth
                        InputLabelProps={{
                            style: {fontSize: '1.6rem'},
                        }}
                        inputProps={{
                            ...params.inputProps,
                            style: {fontSize: '1.4rem'},
                        }}
                        sx={{
                            mt: 3,
                            '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                                backgroundColor: 'white',
                                paddingRight: '5px',
                            },
                        }}
                    />
                )}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                    <li {...props} style={{fontSize: '1.4rem'}}>
                        {option.label}
                    </li>
                )}
                value={getIncomeTypeAutoComplete(incomeType)}
                isOptionEqualToValue={(option, value) => option.incomeType.id === value.incomeType.id}
                onChange={(event: any, newValue: any) => {
                    console.log("event: ", JSON.stringify(event.target.id, null, 2));
                    if (!!newValue) {
                        selectIncomeType(newValue);
                    }
                }}
            />
            </Grid2>
            <Grid2 size={3} sx={{mt: 2}}>
            <Button
                onClick={() => {
                    navigate('/incomeTypes')
                }}
            >
                Edit income types
            </Button>
            </Grid2>

            <Dialog
                fullWidth
                maxWidth={"md"}
                open={editMode}
            >
                <DialogTitle sx={{ml: 2}}>
                    Add income type
                    <CloseDialogButton
                        close={() => {
                            setEditMode(false);
                        }}
                    />
                </DialogTitle>
                <DialogContent>
                    <IncomeTypeEdit
                        currentPayStubId={currentPayStubId}
                        incomeType={getEmptyIncomeType(SALARY_TAX_TREATMENT)}
                        setIncomeType={setIncomeType}
                        close={() => setEditMode(false)}
                    />
                </DialogContent>
            </Dialog>
            <Grid2 size={12} sx={{mt: 1, ml: 1}}>
            <Typography
                variant="body2"
            >
                {incomeType.taxTreatment.description}
            </Typography>
            </Grid2>
        </Grid2>

        <Divider sx={{mt: 2, mb: 0}}/>
        <CloseDialogButton
            close={close}
        />
    </>
}
export default IncomeTypeSelectEdit;