import React from "react";
import EditEmployeeForm from "./EditEmployeeForm";
import {Employee, RecommendedEmployeeAction, useGetEmployeeMostRecentQuery} from "../../graphql/generated/graphql";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import ErrorDisplay from "../../common/ErrorDisplay";
import {Box} from "@mui/material";
import useAnonCookie from "../../security/useAnonCookie";
import EnumSupplier from "../../common/EnumSupplier";
import {getNumber} from "../../Utils/stringMath";

type PropsType = {
    employeeId: number;
    close: () => void;
    employeeAddedEdited: (employee: Employee) => void;
    recommendedActions?: RecommendedEmployeeAction[];
}

const EditEmployee = (props: PropsType) => {

    const {employeeId, close, employeeAddedEdited, recommendedActions} = props;
    const {getAnonUserId} = useAnonCookie();

    const {
        data: employeeData,
        loading: employeeLoading,
        error: employeeError
    } = useGetEmployeeMostRecentQuery({
        variables: {
            employeeId: getNumber(employeeId),
            anonUserId: getAnonUserId()
        }
    });

    if (employeeLoading || employeeError) {
        return <LoadingErrorDisplay
            loading={employeeLoading}
            apolloError={employeeError}
        />
    }
    if (!employeeData || !employeeData.getEmployeeMostRecent) {
        throw new Error("Employee not found. Please contact support");
    }

    const recommendedActionsStrings = recommendedActions?.map(recommendedAction => {
        return recommendedAction.description;
    });
    const recommendedActionDisplay = !!recommendedActionsStrings && recommendedActionsStrings.length > 0
        ? <Box sx={{my: 2}}>
            <ErrorDisplay
                stringError={recommendedActionsStrings}
            />
        </Box>
        : "";
    return <>
        {recommendedActionDisplay}
        <EnumSupplier>
            {({provinces, payFrequencies, claimCodes, dentalCodes}) => (
                <EditEmployeeForm
                    close={close}
                    employee={employeeData.getEmployeeMostRecent.employee}
                    employeeAddedEdited={employeeAddedEdited}
                    hasPayStubs={employeeData.getEmployeeMostRecent.hasPayStubs}
                    provinces={provinces}
                    payFrequencies={payFrequencies}
                    claimCodes={claimCodes}
                    dentalCodes={dentalCodes}
                />
            )}
        </EnumSupplier>
    </>

}

export default EditEmployee;