import React, {useCallback, useEffect, useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material'
import LoadingErrorDisplay from '../../common/LoadingErrorDisplay'
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import CloseDialogButton from "../../common/CloseDialogButton";
import {useEmailPayStubMutation} from "../../graphql/generated/graphql";
import useSystemNotices from "../../Utils/useSystemNotices";


type PropsType = {
    payStubId: number;
}

const PayStubEmail = (props: PropsType) => {
    const {payStubId} = props
    const [errors, setErrors] = useState<string | Array<string> | null | undefined>();
    const [showEmailProgress, setShowEmailProgress] = useState<boolean>(false);
    const {sendNotice} = useSystemNotices();

    const [
        emailPayStub,
        {
            data: emailPayStubData,
            loading: emailPayStubLoading,
            error: emailPayStubError,
        }] = useEmailPayStubMutation({
        variables: {
            payStubId: payStubId,
        },
        fetchPolicy: "no-cache"
    });


    useEffect(() => {
        if (!!emailPayStubData && !emailPayStubData.emailPayStub.successful) {
            setErrors(emailPayStubData.emailPayStub.message);
        }
    }, [emailPayStubData]);

    let sentToAddress = emailPayStubData?.emailPayStub?.sentToAddress;

    const sendPayStubEmail = useCallback(() => {
        setShowEmailProgress(true);
        emailPayStub()
            .catch(error => {
                sendNotice(`Send pay stub email error for pay stub id: ${payStubId}. Error: ${error.message}`);
            });
    }, [emailPayStub, payStubId, sendNotice]);

    return <>
        <Dialog
            open={showEmailProgress}
            fullWidth
            sx={{"& .MuiPaper-root": {maxHeight: 700}}}
        >
            <DialogTitle>
                <Typography
                    variant={"h4"}
                >
                    Email pay stub
                </Typography>
                <CloseDialogButton
                    close={() => setShowEmailProgress(false)}
                />
            </DialogTitle>
            <DialogContent style={{maxHeight: "calc(100vh - 200px)", overflow: 'auto'}}>

                <LoadingErrorDisplay
                    loading={emailPayStubLoading}
                    apolloError={emailPayStubError}
                    stringError={errors}
                />
                {sentToAddress && (
                    <Typography variant={'h6'}>
                        This pay stub has been sent to {sentToAddress}.
                    </Typography>
                )}
            </DialogContent>

            <DialogActions
                style={{justifyContent: 'flex-start'}}>

                <Button
                    sx={{mt: 1, fontSize: '1.5rem'}}
                    variant={'contained'}
                    size={'large'}
                    onClick={() => setShowEmailProgress(false)}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
        <Button
            sx={{mt: 1, mr: 3, fontSize: '1.5rem'}}
            variant={'contained'}
            size={'large'}
            onClick={() => sendPayStubEmail()}
        >
            Send to email
        </Button>
    </>
}
export default PayStubEmail