import {useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {Typography} from "@mui/material";
import {useExecuteAdminActionMutation} from "../graphql/generated/graphql";
import LoadingErrorDisplay from "../common/LoadingErrorDisplay";
import ResultDisplay from "../common/ResultDisplay";

type ParamsType = {
    token: string,
}

const AdminAction = () => {
    const {token} = useParams<ParamsType>();


    const [
        executeAdminAction,
        {
            data,
            loading,
            error
        }
    ] = useExecuteAdminActionMutation();

    useEffect(() => {
        if (token && !loading && !data && !error) {
            executeAdminAction({variables: {token: token}})
                .then()
                .catch(error => {
                    console.log(error);
                })
        }
    }, [token, executeAdminAction, loading, data, error]);


    return <>
        <Typography>
            Admin action page.
        </Typography>

        {
            !!data?.executeAdminAction && (
                <ResultDisplay
                    result={data.executeAdminAction}
                    defaultErrorMessage={"Admin action failed. Please try again."}
                    defaultSuccessMessage={"Admin action completed successfully."}
                />
            )
        }
        <LoadingErrorDisplay
            loading={loading}
            apolloError={error}
        />
    </>
}
export default AdminAction;